* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-appearance: none;
  appearance: none; 
}

body {
  height: 100vh; /* Full viewport height */

  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: black;

}

body * {
  -webkit-font-smoothing: antialiased;
  
}

html, body {
  width: 100%;
  position: relative;
}


.text-center {
  text-align: center !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  box-shadow: inset 0 0 0 50px #fff !important;
  -webkit-text-fill-color: #1b1b1b !important;
  color: #1b1b1b !important;
  background-color: #fff !important;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items a:hover {
  text-decoration: none;
}

.menu-footer  {
  display: flex;
  gap: 40px;
}



.page {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header-container {
  background-color: #fff;
}

.header-body {
  width: 100%;
  height: auto;
  background-color: #fff !important;
  box-shadow: none;
  display: flex;
  justify-content: center;
}


.header-body-container {
  padding: 0 !important;
}

.header-body-container a {
  font-size: 18px;
  font-weight: 600;
}

.body-container {
  width: 100%;
  padding-top: 0px;
}

.project-card-body {
  height: 75%;
  position: relative;
}

.project-card-body > img {
  height: 100%;
  width: 100%;
}

.project-card-body-action {
  position: absolute;
  color: #fff;
  top: 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  padding: 6px 20px 0 9px;
}

.project-card-footer {
  font-size: 18px;
  padding: 10px 20px;
  min-height: 90px;
  position: relative;
}

/* Login Page */
.login-container {
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/login-bg.png") no-repeat center;
  background-color: #0052d3;
  background-size: cover;
  z-index: 0;
  user-select: none;
}

.login-content-container {
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
}

.login-box-container {
    width: 90%;
    
    max-width: 796px;
    height: 465px; /* Fixed height */
    background-color: #fff;
    display: flex;
    z-index: 2;
    justify-content: center;
}

.login-box-container-smaller{ 
  width: 90%;
  min-width: 300px;

    max-width: 796px;
    height: 365px; /* Fixed height */
    background-color: #fff;
    display: flex;
    z-index: 2;
    justify-content: center;

}

.login-content {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    z-index: 2;
    gap:40px
}

.footer .menu-items a {
  font-size: 14px !important;
  font-weight: 600;

}

.footer a:hover {
  font-size: 14px !important;
  color: #0e66f0;
  text-decoration: none;
}

.profile-menu-container {
  height: 100%;
  padding: 21px;
  min-width: 180px;
  gap: 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.profile-menu-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
}

.profile-menu-header-left  {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

p.points {
  color: #0e66f0;
}

.business_staff_grid{
  margin-top: 2rem;
}

.profile-menu-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  font-weight: 600;
}

.profile-menu-footer button:hover {
  background-color: #92969d;
  color: white;
}

.profile-menu-footer button {
  font-weight: bold;
}

.tab-content {
  padding: 0px !important;
}

.tab-content-form {
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

/* Project Grid View  */
.card-media-overlay {
  position: relative;
}

.card-media-overlay .overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0E66F0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  padding-left: 6px;
  padding-right: 15px;

  opacity: 0;
  transition: opacity 0.3s ease;
}


.card-media-overlay:hover .overlay-content {
  
}

.overlay-top {
  font-family: "Assistant", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
   font-size: 14px;
   padding-top: 1px;
  font-weight: 500;
}

.overlay-date {
  font-family: "Assistant", sans-serif;

  padding-right: 15px;
  padding-left: 10px;

  padding-top: 10px;
  display: flex;
  gap: 10px;
  position: absolute;

  color: white;
  font-size: 14px;
  font-weight: bolder;
}

.overlay-menu {
  font-size: 16px;
  position: relative;
}

.menu-icon {
  cursor: pointer;
  font-size: 24px;
  margin-left: 5px;
  margin-top: 3px;
  
}

.menu {
  position: absolute;
  top: 8;
  right: 0;
  background: white;
  color: black;

  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-he {
  position: absolute;
  top: 8;
  left: 0;
  background: white;
  color: black;
  width: 100px;
  display: flex;
  text-align: right;
  flex-direction: column;
  padding: 0px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.menu-en {
  position: absolute;
  top: 8;
  right: 0;
  background: white;
  color: black;
  width: 100px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-item {
  font-size: 14px;

  cursor: pointer;
  padding: 10px;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #E5E5E5;
  opacity :1;
}
.menu-icon:hover + .menu,
.menu:hover {
  display: flex;
}

.overlay-center {
  font-family: "Assistant", sans-serif;
font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.quantity {
  font-size: 20px;
}

.details__description {
  font-size: 20px;
}

.main__content .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px
}

.main__content .items .item {
  flex: 1 1 calc(16.66% - 18px); /* 6 cartes par ligne, moins l'espace entre les cartes */
  max-width: calc(16.66% - 18px); /* Limiter la largeur maximale */
  min-width: 200px; /* Largeur minimale de 200px */
  box-sizing: border-box; /* Inclure le padding et la bordure dans la largeur totale */
}

.main__content .items .item .item__info {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 217px;
  z-index: 4;
}
.main__content .items .item .item__info .imgbx {
  /* background: rgba(14, 102, 240, 0.3) center no-repeat; */
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  transition: 0s;
  left: 0;
  top: 0;
}
.main__content .items .item .item__info .imgbx img {
  width: 100%;
  height: -webkit-max-content;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.main__content .items .item .details {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  transition: 0s;
  top: 0;
  left: 100%;
  background: #0e66f0;
}
.main__content .items .item .details .details__info {
  display: flex;
  z-index: 1;
  height: 100%;
  overflow: hidden;
}
.main__content .items .item .details .details__info .details__btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 13px 0px 0px;
  width: 100%;
  height: 38px;
}

[dir="rtl"] .details__btn {
  justify-content: flex-start !important;
}
.main__content .items .item .details .details__info .details__btn span {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #ffffff;
}
.main__content .items .item .details .details__info .details__text {
  display: flex;
  flex-direction: column;
  margin-top: 51px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  align-items: center;
}
.main__content .items .item .details .details__info .details__list {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 0;
  opacity: 0;
  transform: translateX(100%);
}
.main__content .items .item .details .details__info .details__list ul {
  background-color: #ffffff;
  border: 0.5px solid rgba(196, 196, 196, 0.5);
  width: 165px;
}
.main__content .items .item .details .details__info .details__list ul li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 37px;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 20px 5px 5px;
  color: #2f3b49;
  border-top: 0.5px solid rgba(196, 196, 196, 0.5);
}
.main__content
  .items
  .item
  .details
  .details__info
  .details__list
  ul
  li:nth-child(1) {
  border-top: none;
}
.main__content
  .items
  .item
  .details
  .details__info
  .details__btn:hover
  ~ .details__list,
.main__content .items .item .details .details__info .details__list:hover {
  z-index: 1;
  opacity: 1;
  transform: translateX(0);
}
.main__content .items .item .data_time {
  position: absolute;
  top: 8px;
  left: 14px;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  color: #ffffff;
  z-index: 5;
}
.main__content .items .item .data_time .time {
  margin-right: 15px;
}

.main__content .items .item .data_time .time {
  margin-right: 15px;
}

.main__content .items .item__info:hover .imgbx {
  top: 0;
  left: -100%;
}
.main__content .items .item__info:hover .details {
  top: 0;
  left: 0;
  height: 100%;
}
.main__content .items .item__description {
  width: 100%;
  padding: 12px;
  padding-bottom: 20px;
  height: 89px;
}
.main__content .items .item__description .item__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}
.main__content .items .item__description .item__text {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 27px;
}
.main__content .items .item__description .item__text span {
}

/* Project Grid View  */

/* Project Add/Edit */
.file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 311px;
  width: 311px;
  position: relative;
}

.crop{
  width: 1rem;
}


.file-upload-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload-btn {
  position: absolute;
  top: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.file-upload-btn svg {
  height: auto !important;
  width: auto !important;
}

/* Project Add/Edit */

/* Project List View  */

/* Project List View  */

/* Business Edit */
.business_credits_header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 30px;
}

.business_credits_header_valid {
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.add_credits_form > div {
  margin-bottom: 1rem;
}

.business-table {
  font-size: 14px;
}

.business-setting > .file-upload-content {
}

.business-setting > div[role="presentation"] {
  margin-top: 1rem !important;
}

.table-picture {
  width: 30px;
  position: relative;
  right: -40px;
  top: 0;
  display: inline-block;
  vertical-align: middle;
}

/* Business Edit */

/* PRICING */
.page-title {
  text-align: center;
  padding: 86px 0 20px 0;
}

.title {
  font-size: 36px;
  font-weight: 800;
  margin-bottom: 10px;
}
.title-black {
  color: #2c3b4a;
}
.title-middle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.subtitle-form {
  font-weight: normal;
  font-size: 20px;
}

.page-content {
  position: relative;
}
.page-content-middle {
  max-width: 1000px;
  margin: 0 auto;
}
.page-content-small {
  max-width: 800px;
  margin: 0 auto;
}

.price-block {
  padding: 50px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -15px 15px 0 15px;
  flex-direction: row-reverse;
}
.price-block__col {
  flex: 0 0 320px;
  max-width: 320px;
  padding: 0 15px;
}
.price-block .quantity .input-text.qty {
  font-size: 20px;
  margin: 0 11px;
  width: 70px;
  height: 51px;
  padding: 0 14px 0 0;
}
.price-block .quantity.buttons_added .minus,
.price-block .quantity.buttons_added .plus {
  left: 26px;
}

.price-project {
  width: 100%;
  text-align: center;
  margin: 15px 0;
}
.price-project__head {
  padding: 15px 10px 15px;
  color: #fff;
  overflow: hidden;
}
.price-project__title {
  font-weight: bold;
  font-size: 39px;
  margin-bottom: 4px;
}
.price-project__price {
  font-weight: bold;
  font-size: 68px;
  position: relative;
  bottom: 7px;
  padding-left: 5px;
}
.price-project__currency {
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  bottom: 20px;
  display: inline-block;
  padding-right: 5px;
}
.price-project__info {
  /* min-height: 26px; */
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.price-project__info span {
  font-weight: bold;
  font-size: 18px;
}
.price-project__info .btn {
  margin: auto;
}
.price-project__list {
  padding: 0 20px;
  margin: 0;
  background-color: #f5f7f9;
}
.price-project__list-item {
  font-size: 14px;
  line-height: 33px;
  min-height: 33px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  padding: 0;
}
.price-project__list-item:last-child {
  border-bottom: none;
}

.price-project__btn {
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price-project__input {
  font-size: 20px;
  line-height: 53px;
  width: 70px;
  height: 51px;
  margin: 0 10px;
  padding-right: 14px;
  border: 1px solid #c7c7c7;
  outline: none;
}
.price-project__input:focus {
  outline: none;
}
.price-project__label {
  margin: 0;
}
.price-project--blue .price-project__head {
  background-color: #00b7ea;
}
.price-project--blue .btn-price-project {
  background-color: #00b7ea;
  min-width: 190px;
  position: relative;
  right: 7px;
}
.price-project--blue .btn-price-project:hover {
  background-color: #05c8ff;
}
.price-project--green .price-project__head {
  background-color: #0dcc00;
}
.price-project--green .btn-price-project {
  background-color: #0dcc00;
}
.price-project--green .btn-price-project:hover {
  background-color: #0fe600;
}
.price-project--orange .price-project__head {
  background-color: #ffbb00;
}
.price-project--orange .btn-price-project {
  background-color: #ffbb00;
}
.price-project--orange .btn-price-project:hover {
  background-color: #ffc21a;
}

.price-text {
  padding: 20px 0 40px 0;
  text-align: center;
}
.price-text p {
  margin: -2px 0;
}
#price-plan__info {
  font-size: 36px;
}

.price-project__info span {
  font-weight: bold;
  font-size: 18px;
}

.price-partners {
  padding-left: 30px;
  padding-right: 30px;
}
.price-partners .payments__item {
  height: auto;
  width: auto;
  margin: 5px 0 5px 9px;
}
.price-partners .payments__item img {
  width: 34px;
}
.price-partners .payments__item-small img {
  width: 23px;
}
.price-partners .partners__item {
  height: auto;
  width: auto;
}
.price-partners .partners__item img {
  height: 45px;
}

.btn-with-input {
  font-size: 18px;
  padding: 11px 25px;
  min-width: 165px;
  border: 1px solid transparent;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.partners-block {
  color: rgba(91, 103, 114, 0.7);
  padding: 5px 0;
}

.color-black-light {
  color: #5b6772;
  opacity: 0.7;
  font-weight: 600;
}

.fz-16 {
  font-size: 16px;
}

.payments {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
}

.partners {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
}

.pricing_flex_items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* PRICING */

/* SPINNER */

.loader-spinner,
.loader-spinner:before,
.loader-spinner:after {
  background: #0e4aeb;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader-spinner {
  color: #0e4aeb;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader-spinner:before,
.loader-spinner:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader-spinner:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader-spinner:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
/* SPINNER */

/* Business Staff  */
.business_staff_header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}



.business_staff_add_btn {
  flex: 0 0 180px;
}

.disable_font_color {
  color: rgba(47, 59, 73, 0.7) !important;
}

/* Business Staff  */

.errorsNotistack {
  display: flex;
  flex-direction: column;
}

/* .errorsNotistack > span {
  text-transform: capitalize;
} */

/* REVISION BUTTON STYLES */
.revision-btn-buy {
  display: block;
  background-color: #ffc30b !important;
}

.revision-btn-buy:hover {
  background-color: #e8af03 !important;
}

.revision-btn-run {
  display: block;
  background-color: #1bb81e !important;
}

.revision-btn-run:hover {
  background-color: #009703 !important;
}

.revision-btn-hide {
  visibility: hidden;
}


.revision-btn-resolving-open {
  background-color: #c32800 !important;
}

.revision-btn-resolving-open:hover {
  background-color: #c32800 !important;
}

.revision-btn-resolving-ongoing {
  display: block;
  background-color: #c32800 !important;
}

.revision-btn-failed {
  display: block;
  color: #F04F25 !important;
  background: none !important;
  border: 2px solid #F04F25 !important;
}

.revision-btn-failed:hover {
  color: #fff !important;
  background: #F04F25 !important;
  border: 2px solid #F04F25 !important;
}

.revision-btn-success {
  background: none !important;
  color: #1899FF !important;
  border: 2px solid #1899FF !important;
}

.revision-btn-success:hover {
  background-color: #1899FF !important;
  color: white !important;
  border: 2px solid #1899FF !important;
}

.revision-btn-success-locked {
  display: block;
  background-color: #0052d3 !important;
}

.revision-btn-validating {
  display: block;
  background-color: #ACACAC !important;
  color: white !important;
}

.revision-btn-pending {
  display: block;
  cursor: auto;
  background-color:white !important;
  font-weight: bold;
  color: grey !important;
}


.revision_inputs input {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.revision-container {
  max-width: 100px;
  position: relative;
  max-height: 100px;
  cursor: pointer;
}

.revision-overlay {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  background: #0e66f0; /* Semi-transparent background */
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 100px;
  height: 100px;
  object-fit:cover;
}

.revision-container:hover .revision-overlay {
  opacity: 1;
}

.revision-overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: small;
}

.file-uploaded.image-upload-wrap.show {
  background-color: #fff;
  border: 1px solid #c7c7c7;
}

.file-upload-medium.file-uploaded.image-upload-wrap .show {
  position: absolute;
  width: 312px !important;
  height: 312px !important;
}

.file-uploaded.image-upload-tiny-wrap.show {
  background-color: #fff;
  border: 1px solid #c7c7c7;
}

.tooltip-area:hover .tooltip-contents {
  visibility: visible;
}

.tooltip-area {
  width: 100%;
  height: 100%;
  position: relative;
display: flex;
flex-direction: column;
}

.tooltip-contents {
  visibility: hidden;
  width: 865px;
  display: inline ;
  max-height: 300px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.90);
  overflow: hidden;
}


.tooltip-scroll::-webkit-scrollbar {
  width: 1em;
}

.tooltip-scroll::-webkit-scrollbar-track {
  background-color: #1a2027;
}

.tooltip-scroll::-webkit-scrollbar-thumb {
  background-color: #556475;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 50px;
  padding: 10px 20px 10px 10px;
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #556475 #1a2027;
}

.grid-container::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.grid-container::-webkit-scrollbar-track {
  background-color: #1a2027;
  border-radius: 0 10px 10px 0;
}

.grid-container::-webkit-scrollbar-thumb {
  background-color: #556475;
  border-radius: 10px;
}

.grid-item {

  color: #fff;
  padding: 0;
  min-width: 50px;
  font-size: 13px;
  text-align: start;
 
}

.all_stats ul {
  margin: 0 0 0 -0.8125em;
  padding-left: 0;
}

.all_stats li {
  position: relative;
  padding-left: 11px;
  margin: 0 0 23.5px 0;
}

.all_stats li {
  border: 1px solid #c7c7c7;
  background-color: #fff;
  height: 29%;
  min-height: 130px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all_stats {
  position: relative;
  height: 100%;
  width:100%;
  @media (max-width: 1200px) {
    display: flex ;
    flex-direction: column ;
    gap: 3rem;
    height: 100px;

    flex-wrap: wrap;
    justify-content: center;
  }
}

.stats-center {
  display: flex;
}

.stats-center > div {
  margin: 0;

}

.stats-section {
  width: 100%;
  background-color: #fff;
}

/* REVISION BUTTON STYLES */

/* Admin Ticket Resolution */
.ticket-grid-files-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.ticket-grid-files-container > div {
  font-size: 30px;
}

.ticket_CompanyDetails_Title {
  font-weight: bold !important;
  font-size: 2rem !important;
  line-height: 2rem !important;
  color: #2f3b49 !important;
}

.ticket_CompanyDetails {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #2f3b49 !important;
}
/* Admin Ticket Resolution */

.rating-group {
  fill: #fff;
  font-size: 20px;
}

.hreport-group {
  fill: #fff;
  font-size: 18px;
}

.multiply-units {
  fill: #fff;
  font-size: 13px;
}

.secondary-btn {
  background-color: #fff !important;
  color: #92969d !important;
  border: 2px solid #92969d !important;
}

.secondary-btn:hover {
  background-color: #92969d !important;
  color: #ffffff !important;
}



.ActiveICONN {
  fill: #0e66f0 !important;
}

.ActiveICONN > * {
  fill: #0e66f0 !important;
}

.InactiveICONN {
  fill: #c6ccd4 !important;
}

.InactiveICONN > * {
  fill: #c6ccd4 !important;
}

.item__project_type {
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.animation {
  animation: rotate 4s linear infinite;
}

.term_link {
  text-decoration: underline;
}

.visibleSort {
  display: block;
}

.hiddenSort {
  display: none;
}

.borderBottom {
display: flex;
justify-content: flex-start;
padding: 0px;
}

.borderBottomNone {
  border-bottom: none !important;
}

.revision-error-dialog .MuiPaper-root {
 /*  width: 55vw !important; */
 width: auto;
}

.revision-error-dialog .MuiDialogContent-root {
  width: auto;
 /*  width: 55vw !important; */
}

.dialog-title {
  font-size: 24px !important;
  text-align: center !important;
}

.dialog-content {
  font-size: 18px !important;
}

.list-item {
  padding: 10px;
  border: 1px solid #888;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background: #eee;
}

.App {
  max-width: 100vw;
  overflow-x: hidden;
}

.grid-container::-webkit-scrollbar {
  width: 8px;
}

.grid-container::-webkit-scrollbar-track {
  background-color: #1a2027;
  border-radius: 0 10px 10px 0;
}

.grid-container::-webkit-scrollbar-thumb {
  background-color: #556475;
  border-radius: 10px;
}
